<template>
    <!-- 拆书-查看 弹窗 -->
    <el-dialog
      class="custom-dialog new-changpian-dialog"
      ref="dialog-detail"
      :visible.sync="showBreakdownDetailDialogFlag"
      :append-to-body="true"
      :modal-append-to-body="false"
      :close-on-press-escape="true"
      :withHeader="false"
      :destroy-on-close="true"
      :show-close="true"
      :close-on-click-modal="true"
      :wrapperClosable="true"
      :width="drawerWidth"
      :top="dialogTop"
      @close="closeDetail" >
        <div class="" style="margin-top: 1rem;">
          <h3 style="color: rgba(0,0,0,0.9);">拆书查看</h3>
          <!-- <div style="font-weight: 600;font-size: 1.17em;color: rgba(0,0,0,0.9);">拆书查看</div> -->
          <Tabs @input="handleTabClick" :value="activeTabIndex" v-if="enums.BookBreakdowTypeEnum">
            <TabItem :name="item.desc"
                v-for="(item, index) in enums.BookBreakdowTypeEnum.filter((item) => {
                    return item.code <= 2
                  })"
                :key="index" >
              <div v-if="item.name == 'STORY_YAOSU'" class="tab-item-content mali-scrollbar" v-html="replaceLineBreaks(uploadFile.yaosuContent)"></div>
              <div v-if="item.name == 'STORY_DAGANG'" class="tab-item-content mali-scrollbar" v-html="replaceLineBreaks(uploadFile.dagangContent)"></div>
              <div v-if="item.name == 'STORY_ZHANGGANG'" class="tab-item-content mali-scrollbar" v-html="replaceLineBreaks(uploadFile.zhanggangContent)"></div>
              <div v-if="item.name == 'YAOSU_MUBAN'" class="tab-item-content mali-scrollbar" v-html="replaceLineBreaks(uploadFile.yaosuTemplate)"></div>
              <div v-if="item.name == 'DAGAN_MUBAN'" class="tab-item-content mali-scrollbar" v-html="replaceLineBreaks(uploadFile.dagangTemplate)"></div>
              <div v-if="item.name == 'ZHANGGAN_MUBAN'" class="tab-item-content mali-scrollbar" v-html="replaceLineBreaks(uploadFile.zhanggangTemplate)"></div>
              <div class="" style="">
                <div class="tab-item-copy pointer" @click="copyTextToClipboard(item)">复制</div>
                </div>
            </TabItem>
          </Tabs>
        </div>
    </el-dialog>
</template>
<script>
import * as FileApi from '@/api/file'
import { mapState, mapMutations} from 'vuex'
export default {
  name: 'BreakdownDetail',
  props: {
    enums: {
      type: Object,
      default: {ArticleTypeEnum: []}
    },
    uploadFile: {
      type: Object,
      default: {}
    },
  },
  components: {

  },
  data() {
    return {
      elementData: [],
      activeTabIndex: 0,
      activeTab: {
        name: 'STORY_YAOSU',
        desc: '故事要素'
      },
    }
  },
  mounted() {
    console.log('breakdown detail');
    window.addEventListener('resize', this.forceUpdate);
    this.handleContent();
  },
  computed: {
    ...mapState(['user', 'story', 'showBreakdownDetailDialogFlag']),
    drawerWidth() {
      return window.innerWidth <= 768 ? '100%' : window.innerWidth <= 1200 ? '80%' : '60%';
    },
    dialogTop() {
      return window.innerWidth <= 768 ? "5vh" : '10vh';
    },
    dialogTitle () {
      return '拆书查看'
    },
    showHeader() {
      return window.innerWidth <= 768
    },
  },
  created() {
    console.log('created');
  },
  methods: {
    ...mapMutations(['setStory', 'setShowBreakdownDetailDialogFlag']),
    closeDetail(){
      this.setShowBreakdownDetailDialogFlag(false);
    },
    replaceLineBreaks(str) {
      return str?str.replace(/\\n/g, '\n').replace(/\n/g, '<br>'):"";
    },
    handleContent() {
      // FileApi.view({id: uploadFileId}).then(res => {
      //   let result = res.data.data;
      //   this.elementData = result.records
      // });
    },
    handleTabClick(tab, event) {
      console.log('tab: ', tab, event);
      this.activeTabIndex = tab
      // this.activeTab.desc = tab.label
    },
    forceUpdate() {
      this.$forceUpdate(); // 强制重新渲染，以更新计算属性
    },
    copyTextToClipboard(item) {

      const textArea = document.createElement('textarea');
      textArea.value = item.desc;
      textArea.style.position = 'fixed';  // 防止出现滚动条
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        const successful = document.execCommand('copy');
        if (successful) {
          console.log('成功复制到剪贴板');
          this.$message.success('复制成功');
        } else {
          this.$message.error('复制失败');
          console.error('复制失败');
        }
      } catch (err) {
        this.$message.error('复制时出错');
        console.error('复制时出错: ', err);
      }
      document.body.removeChild(textArea);
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.forceUpdate);
  },
}
</script>

<style scoped lang="scss">
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-form--label-top .el-form-item__label {
  padding: 0;
  font-weight: normal;
}
/deep/ .vmt__nav__items .vmt__nav__item{
  font-size: 14px;
}
.tab-item-content {
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 50vh;
  background: rgba(206,208,218,0.19);
  border-radius: 16px 16px 16px 16px;
  padding: 1rem;
  font-size: 14px;
  line-height: 28px;
  text-align: justify;
  overflow-y: auto;
  overflow-x: hidden;
}
.tab-item-copy {
  padding: .5rem 1rem;
  color: #0052D9;
  border-radius: 6px;
  width: 4rem;
  float: right;
  border: 1px solid #0052D9;
}
.tab-item-copy:hover {
  background-color: rgba(0, 0, 0, 0.11);
}
/* 全局滚动条轨道的padding */
.mali-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
  // padding: 20px 0; /* 上下padding */
  margin: 1rem 0;
}

/* 滚动条的样式 */
.mali-scrollbar::-webkit-scrollbar {
  width: 8px;
  margin: 1rem 0;
  background-color: transparent;
}

.mali-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  // border-radius: 6px;
  // background-color: transparent;
}
</style>
