<template>
  <el-row type="flex" justify="space-between" align="center" style="padding: 0.5rem 1rem; line-height: 3rem; background: #242424;">
    <div @click="$router.push('/home')" class="pointer" style="max-width: 15rem;width: 50%;">
      <img style="width: 100%;" src="@/assets/logo1.png" />
    </div>
    <div class="d-flex flex-y-center">
      <div @click="onInvite" style="cursor: pointer;display: inline-block">
        <img src="@/assets/btn-invite-energy.png" style="width: 6.5rem;margin-top: 5px;margin-right: 10px;">
      </div>
      <el-dropdown @command="handleCommand" :trigger="(!isMobileFlag)?'hover':'click'" ref='dropShow' @visible-change='trueOrfalse'>
        <div style="line-height: 2rem;height: 2rem;cursor: pointer; display: flex;margin-top: .5rem;background: linear-gradient( 90deg, rgba(0, 82, 217, .4) 0%, rgba(58, 231, 255, .4) 49%, rgba(74, 71, 255, .4) 100%);border-radius: 60px;">
          <img src="@/assets/user-energy.png" style="width: 2rem;margin-left: 2px;">
          <div style="padding: 0 1rem;font-weight: bold;color: rgba(255,255,255,1);">{{ user.score }}</div>
        </div>
        <el-dropdown-menu class="custom-dropdown" slot="dropdown" style="background: #404040;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.1), 0px 8px 10px 1px rgba(0,0,0,0.06), 0px 3px 14px 2px rgba(0,0,0,0.05);
  border-radius: 6px;border: none;">
          <el-dropdown-item command="xx">
            <div style=" display: flex;align-items: center;">
              <div style=""><img src="@/assets/touxiang.png" style="width: 1.5rem;"><span style="">账号：{{ user.phone }}</span></div>
            </div>
          </el-dropdown-item>
          <el-dropdown-item command="recharge">
            <div style=" display: flex;align-items: center;">
              <div><img src="@/assets/recharge-icon.png" style="width: .9rem;"><span style="">充值</span></div>
            </div>
          </el-dropdown-item>
          <el-dropdown-item command="setting">
            <div style=" display: flex;align-items: center;">
              <div><img src="@/assets/icon-menu-setting.png" style="width: .9rem;"><span style="">设置</span></div>
            </div>
          </el-dropdown-item>
          <el-dropdown-item command="orderlist">
            <div style=" display: flex;align-items: center;">
              <div><img src="@/assets/orderlist-icon.png" style="width: .9rem;"><span style="">购买记录</span></div>
            </div>
          </el-dropdown-item>
          <el-dropdown-item command="energylist">
            <div style=" display: flex;align-items: center;">
              <div><img src="@/assets/energylist-icon.png" style="width: .9rem;"><span style="">能量明细</span></div>
            </div>
          </el-dropdown-item>
          <el-dropdown-item command="logout">
            <div style=" display: flex;align-items: center;">
              <div><img src="@/assets/logout-icon.png" style="width: .9rem;"><span style="">退出登录</span></div>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!-- 能量充值 弹窗 -->
    <el-dialog :append-to-body="true" class="custom-dialog recharge-dialog" ref="rechargeDialog"
      :visible.sync="dialogShowRechargeFlag"
      @close="close">
        <div class="recharge">
          <div slot="title" class="recharge-user-bg">
            <div class="recharge-user-img d-flex flex-y-center">
              <div class="recharge-user-avatar"><img src="@/assets/touxiang-2.png" style="width: 3.375rem;"></div>
              <div class="recharge-user-info">
                <div class="recharge-user-info-item">账号：{{ user.phone }}</div>
                <div class="recharge-user-info-item">能量：{{ user.score }}</div>
              </div>
            </div>
          </div>
          <div class="recharge-title">
            <div class="recharge-title-box d-flex flex-y-center" style="justify-content: center; padding: .5rem;">
              <span><img style="width: 1rem" src="@/assets/recharge-bar.png" alt="" /></span>
              <span class="recharge-title-box-text" style="">能量补给站</span>
            </div>
          </div>
          <div style="position: relative;">
            <div class="recharge-energy d-flex container scroll-transition" style="border-right: 1px solid rgba(0, 0, 0, .1);position: relative;" ref="scrollContainer">
              <div class="recharge-energy-item" style="position: relative;" :class="{'recharge-energy-item-active': energyActivedIndex == index}" @click="energyActive(index)"
                v-for="(item,index) in energyCards" :key="index">
                <span v-if="index == 0" style="background: transparent;padding: 0.25rem .5rem;color: transparent;
                  border-radius: 20px 0px 20px 0px;position: absolute;left: -4px;top: -4px;">{{item.brief}}</span>
                <span v-else style="background: #0052D9;padding: 0.25rem .5rem;
                  border-radius: 20px 0px 20px 0px;position: absolute;left: -4px;top: -4px;">{{item.brief}}</span>
                <div class="recharge-energy-item-title recharge-energy-item-text d-flex flex-y-center" style="margin: 0 1rem;margin-top: 1.8rem;">
                  <div class="" style="width: 1.8rem;height: 1.8rem;">
                    <img src="@/assets/energy-icon.png" style="width: 100%;padding-bottom: .9rem;"/>
                  </div>
                  <div class="text-gradient" style="display: inline-block !important;width: auto;">{{item.title}}</div>
                </div>
                <div class="recharge-energy-item-text" style="margin-top: 1rem;">
                  <span style="font-size: 16px;padding: 0;">￥</span>
                  <span>{{item.price}}</span>
                </div>
                <div v-if="index > 0" class="recharge-energy-item-text">
                  <span style="font-weight: 400;font-size: 14px;color: rgba(0,0,0,0.4);">￥</span>
                  <span style="font-weight: 400;font-size: 20px;color: rgba(0,0,0,0.4);text-decoration: line-through;">{{item.screenPrice}}</span>
                </div>
                <div style="height: 1.5rem;"></div>
              </div>
            </div>
            <!-- 左右箭头 -->
            <div v-if="!isMobileFlag" class="d-flex" :style="{'position': 'absolute','left': '2.2rem', 'top': '42%'}">
                <span style="" class="slider-arraw el-icon-arrow-left" @click="scrollLeft"></span>
              </div>
              <div v-if="!isMobileFlag" class="d-flex" style="position: absolute;right: 2.2rem; top: 42%;">
                <span style="" class="slider-arraw el-icon-arrow-right" @click="scrollRight"></span>
              </div>
          </div>
          <div class="" style="font-weight: 400;font-size: 12px;color: #3B81F4;padding: .5rem 0;margin: 0 2rem;">
            *直接购买的能量永久有效
          </div>
          <div class="recharge-pay d-flex flex-between flex-y-center">
            <div class="recharge-pay-left">
              <div class="">
                <span style="font-weight: 400;font-size: 14px;color: #151515;">支付金额：</span>
                <span style="font-weight: 400;font-size: 16px;color: #0052D9;">￥</span>
                <span style="font-weight: 600;font-size: 2rem;color: #0052D9;">{{energyCard.price}}</span>
              </div>
              <div class="" style="margin-top: 1rem;">
                <span style="font-weight: 400;font-size: 14px;color: #151515;">支付即视为您已同意</span>
                <a style="font-weight: 400;font-size: 14px;color: #0052D9;" target="_blank" href="recharge.html">《能量充值协议》</a></a>
              </div>
            </div>
            <div class="recharge-pay-right" style="text-align: right;">
              <el-button type="primary" v-if="isMobileFlag" @click="goRecharge">充值</el-button>
              <img style="width: 7rem;border: 2px solid #ffffff;" v-else :src="imgUrl"/>
              <div class="d-flex flex-y-center" v-if="!isMobileFlag" style="font-size: 12px;color: #151515;padding: .2rem 0;">
                <img src="@/assets/pay-icon.png" style="width: 31px; height: 13px;padding-right: .2rem;"/>
                <span>支付宝/微信扫码支付</span>
              </div>
            </div>
          </div>
          <div style="height: 1rem;"></div>
        </div>
    </el-dialog>

    <!-- 新人大礼包 -->
    <el-dialog :append-to-body="true" class="newer-gift-dialog"
      title=""
      :visible.sync="dialogShowNewerGiftFlag"
      @close="close"
      style="text-align: center;">
        <img style="width: 30vw;" src="@/assets/newer-gift.png" @click="close"/>
    </el-dialog>

    <!-- 充值成功 弹窗 -->
    <el-dialog :append-to-body="true" class="custom-dialog writer-cashier-dialog"
      title=""
      :visible.sync="dialogShowPaySuccessFlag"
      @close="close('pay-success')"
      style="margin: 0 auto;">
        <div class="writer-cashier">
          <div class="writer-cashier-flag d-flex flex-y-center" style="width: 100%;">
            <img src="@/assets/pay-success.png" style="width: 3.75rem;"/>
            <div class="" style="font-size: 2rem; padding: .5rem;font-weight: 600; color: #151515;">
              充值成功
            </div>
          </div>

          <div class="writer-cashier-info">

            <div class="writer-cashier-info-item">
              <span>商品：</span>
              <span>{{energyPaySuccess.sku?energyPaySuccess.sku.title:''}}</span>
            </div>

            <div class="writer-cashier-info-item">
              <span>金额：</span>
              <span>￥{{energyPaySuccess.payMoney}}</span>
            </div>

            <div class="writer-cashier-info-item">
              <span>手机号：</span>
              <span>{{energyPaySuccess.phone}}</span>
            </div>

            <div class="writer-cashier-info-item">
              <span>用户ID：</span>
              <span>{{energyPaySuccess.accountId}}</span>
            </div>
          </div>

        </div>
    </el-dialog>

    <!-- 能量耗尽 弹窗 -->
    <el-dialog :append-to-body="true" class="custom-dialog"
      title=""
      :visible.sync="dialogShowCostomContactFlag"
      @close="close"
      style="text-align: center;width: 40rem; margin: 0 auto;">
        <div class="d-flex flex-y-center">
          <img style="width: 2rem; height: 2rem;" src="@/assets/no-energy.png"/>
          <h2 style="color: #151515;">抱歉，您的能量不足</h2>
        </div>
        <div style="color: #151515;padding-left: 2rem;text-align: left;">如需继续使用，请充值能量</div>
        <div class="" style="margin-top: 3rem; margin-bottom: 1rem;">
          <el-button type="primary" class="custom-button" style="border-radius: 10px;" :size="$root.buttonSize" @click="recharge4noEnergy">充值</el-button>
        </div>
    </el-dialog>

    <!-- 设置 弹窗 -->
    <el-dialog :append-to-body="true" class="custom-dialog"
      title=""
      :visible.sync="showSettingFlag"
      @close="close"
      style="text-align: center;margin: 0 auto;">
        <div class="d-flex flex-y-center">
          <span style="color: #000000;">创作故事时，AI 是否自动生成故事要素、大纲、正文等</span>
          <span>
            <el-switch
              v-model="user.desktopFlag"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </span>
        </div>
        <div class="" style="margin-top: 3rem; margin-bottom: 1rem;">
          <el-button type="primary" class="custom-button" style="border-radius: 10px;" :size="$root.buttonSize" @click="handleUpdateSetting()">保存</el-button>
        </div>
    </el-dialog>

    <!-- 邀请赚能量 弹窗 -->
    <div id="inviteText" v-show="false">
      {{getInviteText()}}
    </div>
    <el-dialog v-if="!isMobile()" :append-to-body="true" class="invite-newer-dialog"
      title=""
      :visible.sync="showInviteFlag"
      @close="close"
      style="margin: 0 auto;width: 68rem;border: none;">
      <div class="" style="margin-left: 1.2rem;margin-right: 1.2rem;">

        <div style="margin-top: 10rem;">
          <h2 style="color: #151515;">邀请新用户，免费领能量</h2>
        </div>
        <div style="color: #151515;margin-top: 1rem">
          每邀请1位新用户注册你可得 <label style="color: #0252D9;font-size: 1.1rem;">5万</label>能量，新用户可得 <label style="color: #0252D9;font-size: 1.1rem;">10万</label>能量
        </div>

        <div style="color: #151515;margin-top: 2rem;text-align: center;margin-right: 2rem;">
          你的专属邀请链接
        </div>

        <div style="padding: 1rem 3rem 0 1rem; text-align: center;">
          <div style="line-height: 1.25rem;color: #4E4E4E;padding: 1rem;text-align: justify;border-radius: 20px;background: #F7F6F6;">
            {{getInviteText()}}
          </div>
        </div>

        <div @click.stop="copyInviteText" style="text-align: center;cursor: pointer;margin-right: 2rem;">
          <div class="invite-button" @click.stop="copyInviteText" style="position: relative;margin-top: 1rem; background: linear-gradient( 270deg, #0052D9 0%, #00FFC2 100%);
          border-radius: 20px;display: inline-flex;padding: .5rem 4rem;">
                    {{inviteBtnText}}
           </div>
        </div>
        <div style="color: #A5A5A5;margin-top: 1rem;text-align: left;line-height: 1.25rem;">
          提示：
<br>
          1、请务必提醒好友用你的专属链接登录网站
<br>
          2、好友登录后，预计能量 5 分钟内到账
<br>
          3、邀请多个新用户可重复获得奖励
<br>
          4、严禁使用作弊手段恶意注册、刷能量，若有发现则封号处理
<br>
          5、本活动最终解释权归码哩写作所有
        </div>
      </div>
    </el-dialog>

    <el-dialog v-if="isMobile()" :append-to-body="true" class="invite-newer-dialog invite-newer-dialog-mobile"
      title=""
      :visible.sync="showInviteFlag"
      @close="close"
      width="100%"
      style="border: none;">
      <div class="" style="">
        <div style="margin-top: 10.1rem;">
          <h2 style="color: #151515;">邀请新用户，免费领能量</h2>
        </div>
        <div style="color: #151515;margin-top: 1rem">
          每邀请1位新用户注册你可得 <label style="color: #0252D9;font-size: 1.1rem;">5万</label>能量，新用户可得 <label style="color: #0252D9;font-size: 1.1rem;">10万</label>能量
        </div>

        <div style="color: #151515;margin-top: 2rem;text-align: center;">
          你的专属邀请链接
        </div>

        <div style="padding: 1rem 1rem 0 1rem; text-align: center;">
          <div style="line-height: 1.25rem;color: #4E4E4E;padding: 1rem;text-align: justify;border-radius: 20px;background: #F7F6F6;">
            {{getInviteText()}}
          </div>
        </div>

        <div @click.stop="copyInviteText" style="text-align: center;cursor: pointer;">
          <div class="invite-button" @click.stop="copyInviteText" style="position: relative;margin-top: 1rem; background: linear-gradient( 270deg, #0052D9 0%, #00FFC2 100%);
          border-radius: 20px;display: inline-flex;padding: .5rem 4rem;">
                    {{inviteBtnText}}
           </div>
        </div>
        <div style="color: #A5A5A5;margin-top: 1rem;text-align: left;line-height: 1.25rem;">
          提示：
<br>
          1、请务必提醒好友用你的专属链接登录网站
<br>
          2、好友登录后，预计能量 5 分钟内到账
<br>
          3、邀请多个新用户可重复获得奖励
<br>
          4、严禁使用作弊手段恶意注册、刷能量，若有发现则封号处理
<br>
          5、本活动最终解释权归码哩写作所有
        </div>
      </div>
    </el-dialog>

    <!-- menu v-if="isXsOrDown && isInPagePath"-->
    <div class="pointer" v-if="isXsOrDown && isInPagePath" @click="setShowMenuFlag(!showMenuFlag)"
     style="position: absolute;
       top: 10vh;
       left: 0;
       width: 30px;height: 50px;
       display: flex;align-items: center;justify-content: center;
       background: #E5E7FC;
       z-index: 1002;
       border-radius: 0 10px 10px 0;">
       <img style="width: 20px;height: 20px;" src="../assets/daohang2.svg" alt="" />
    </div>
    <StoryIdeaDialogMenu v-if="isXsOrDown && isInPagePath" channel="page_menu" />
  </el-row>
</template>
<script>
import StoryIdeaDialogMenu from '@/components/StoryIdeaDialog/index_menu'
import * as AccountApi from '@/api/account'
import * as SkuApi from '@/api/sku'
import * as OrderApi from '@/api/order'
import QRCode from 'qrcode'
import { mapState, mapMutations} from 'vuex'
export default {
  name: 'HeadNavWriter',
  components: {
    StoryIdeaDialogMenu
  },
  data() {
    return {
      isXsOrDown: false,
      inPagePath:['/bookbreakdown/index', '/home'],
      // currentPagePath: '',
      intervalId: null,
      energyCards: [],
      energyCard: {},
      energyPaySuccess: {},
      imgUrl: '',
      energyActivedIndex: 0,
      showInviteFlag: false,
      isMobileFlag: false,
      boxLeftFlag: false,
      payTime: null,
      inviteBtnText: '一键复制邀请链接',
      rechargeHeigh: '0',
      realOffsetWidth: 0,
      realScrollWidth: 0,
      showSettingFlag: false
    }
  },
  mounted() {
    window.addEventListener('resize', this.checkWindowSize);
    window.addEventListener('popstate', () => {
      console.log('URI 变化了，当前路径是：', window.location.pathname);
    });
    this.checkWindowSize()

    // this.currentPagePath = this.$router.history.current.path;
    if(this.isMobile()){
      this.isMobileFlag = true
    }
    this.syncUser();
    this.handlePageSku();
    this.handleNewerGift();

    // this.setDialogShowCostomContactFlag(true);

  },
  computed: {
    ...mapState(['story', 'user', 'shoe','showMenuFlag',
    'dialogShowCostomContactFlag',
    'dialogShowRechargeFlag',
    'dialogShowPaySuccessFlag',
    'dialogShowNewerGiftFlag']),
    boxLeft(){
      return this.realScrollWidth - this.realOffsetWidth
    },
    boxRight(){
      return this.realOffsetWidth
    },
    innerWidth() {
      return window.innerWidth
    },
    isInPagePath() {
      console.log('mounted，新的路径是：', this.$router.history.current.path);
      return this.inPagePath.indexOf(this.$router.history.current.path) >= 0
    }
  },
  created() {

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkWindowSize);
    window.addEventListener('popstate', () => {
      console.log('URI 变化了，当前路径是：', window.location.pathname);
    });
  },
  methods: {
    ...mapMutations(['SET_USER', 'setDialogShowRechargeFlag',
    'setDialogShowCostomContactFlag','setShowMenuFlag',
    'setDialogShowPaySuccessFlag',
    'setDialogShowNewerGiftFlag']),
    checkWindowSize() {
      console.log('innerWidth', window.innerWidth);
      if (window.innerWidth < 768) {
        this.isXsOrDown = true;
      }else{
        this.isXsOrDown = false;
      }
    },
    async copyInviteText() {
      let text = document.getElementById('inviteText').innerHTML
      console.log('invite text', text);
      const textArea = document.createElement('textarea');
      textArea.value = text.trim();
      textArea.style.position = 'fixed';  // 防止出现滚动条
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        const successful = document.execCommand('copy');
        if (successful) {
          this.inviteBtnText = '复制成功'
          console.log('成功复制到剪贴板');
        } else {
          console.error('复制失败');
        }
      } catch (err) {
        console.error('复制时出错: ', err);
      }
      document.body.removeChild(textArea);
    },
    close(fromFlag){
      this.energyActivedIndex = 0
      this.energyActive(0);
      this.setDialogShowCostomContactFlag(false);
      this.setDialogShowRechargeFlag(false);
      this.setDialogShowNewerGiftFlag(false);
      this.setDialogShowPaySuccessFlag(false);
      if (this.intervalId) {
        // 清除定时器，停止周期性执行
        clearInterval(this.intervalId);
      }
      this.syncUser()
      if(fromFlag && fromFlag == 'pay-success'){
        window.location.reload();
      }
    },
    getInviteText(){
        return '写作苦手？码哩写作AI让你轻松变身爆款作家！一天轻松搞定10篇短篇，长篇日更只需3分钟！限时免费送10W能量，快来领取你的创作金手指👉'+process.env.VUE_APP_INVITE_URL+'?invite='+this.user.id
    },
    trueOrfalse(drop){
        console.log(1, drop)
    },
    //让下拉菜单显示的方法
    showDrop(){
        this.$refs.dropShow.show()
    },
    //让下拉菜单隐藏的方法
    hideDrop(){
        this.$refs.dropShow.hide()
    },
    recharge4noEnergy(){
      this.close();
      this.$nextTick(()=>{
        this.setDialogShowRechargeFlag(true);
        this.getScrollInfo()
      })
    },
    energyActive(index){
      this.energyActivedIndex = index
      this.energyCard = this.energyCards[index]
      this.generateQRCode();
    },
    getScrollInfo(){
      this.$nextTick(()=>{
        const scrollContainer = this.$refs.scrollContainer;
        console.log(`scrollContainer: `, scrollContainer);
        const offsetWidth = scrollContainer.offsetWidth; // 可视宽度
        const scrollWidth = scrollContainer.scrollWidth; // 实际宽度
        this.realScrollWidth = scrollWidth
        this.realOffsetWidth = offsetWidth
        console.log(`scrollContainer: `, scrollWidth, offsetWidth);
      })
    },
    scrollLeft() {
       this.$refs.scrollContainer.scrollTo({
         left: 0,
         behavior: 'smooth' // 启用平滑滚动动画
       });
       this.boxLeftFlag = false
    },
    scrollRight() {
        const scrollWidth = this.$refs.scrollContainer.scrollWidth;
        const viewWidth = this.$refs.scrollContainer.clientWidth;
        this.$refs.scrollContainer.scrollTo({
          left: scrollWidth - viewWidth,
          behavior: 'smooth' // 启用平滑滚动动画
        });
        this.boxLeftFlag = true
    },
    getRechargeUrl(){
       return process.env.VUE_APP_BASE_API_URL + 'auth-service/payments/wx/redirect?productId='+ this.energyCard.id +'&Authorization=' + localStorage.getItem('lyToken')
    },
    goRecharge(){
      window.location.href = this.getRechargeUrl()
    },
    onInvite(){
      this.showInviteFlag = true;
    },
    generateQRCode() {
      let url = this.getRechargeUrl()
      console.log(url)
      SkuApi.page({size:1,shortcutUrl:url}).then(res => {
        console.log('设置短连接')
        // With promises
        // 二维码的选项
        let options = {
            errorCorrectionLevel: 'H', // 设置错误矫正级别
            margin: 4,                // 设置边距
            width: 256,               // 设置二维码的宽度
            height: 256               // 设置二维码的高度
        };
        QRCode.toDataURL(process.env.VUE_APP_BASE_API_URL + 'auth-service/payments/wx/shortcut?accountId='+ this.user.id)
          .then(url1 => {
            // console.log(url)
            this.imgUrl = url1
          })
          .catch(err => {
            console.error(err)
          })
      })
    },
    isMobile(){
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
    syncUser(){
      setTimeout(()=>{
        AccountApi.view(this.user.id).then(res => {
          this.SET_USER(res.data.data)
        })
      }, 1000)

    },
    handleNewerGift(){
      console.log('coFlag', this.user.coFlag);
      // this.setDialogShowNewerGiftFlag(true)
      if(this.user.coFlag){
        return false;
      }
      AccountApi.newerGift().then(res => {
        let result = res.data.data;
        if(res.data.code == 0 && result){
          this.setDialogShowNewerGiftFlag(true)
        }else{
          this.setDialogShowNewerGiftFlag(false)
        }
      })
    },
    handleUpdateSetting(){
      console.log('desktopFlag', this.user.desktopFlag);
      // this.setDialogShowNewerGiftFlag(true)
      AccountApi.update({id: this.user.id, desktopFlag: this.user.desktopFlag}).then(res => {
        this.syncUser()
        this.showSettingFlag = false
      })
    },
    handlePageSku() {
      SkuApi.page({skuType: 'ENERGY_CARD', publishFlag: true}).then(res => {
        let result = res.data.data;
        this.energyCards = result.records
        this.energyCard = this.energyCards[this.energyActivedIndex]

        this.generateQRCode();
      })
    },
    handleCommand(cmditem) {
      this.$refs.dropShow.hide()
      if (!cmditem) {
        this.$message('菜单选项缺少command属性')
        return false
      }
      switch (cmditem) {
        case 'setting':
          // 设置
          this.showSettingFlag = true;
          break
        case 'recharge':
          // 充值
          this.handlePageSku();
          this.setDialogShowRechargeFlag(true)
          this.$nextTick(()=>{
            this.getScrollInfo()
          })
          // 启动周期性任务
          this.payTime = this.$time.parseTime(new Date().getTime())
          this.intervalId = setInterval(this.periodicTask, 2000); // 每1000毫秒（1秒）检查一次任务

          break
        case 'orderlist':
          // 订单列表
          this.$router.push('/account/orders')
          break
        case 'energylist':
          // 能量列表
          this.$router.push('/account/energies')
          break
        case 'logout':
          // 清除
          localStorage.removeItem('lyToken')
          localStorage.removeItem('lyAccount')
          localStorage.removeItem('lyActions')
          localStorage.removeItem('activeId')
          localStorage.removeItem('currentId')
          localStorage.removeItem('currentPath')
          localStorage.removeItem('processInstanceId')
          localStorage.removeItem('queueName')

          this.$store.dispatch('setToken', '')
          this.$store.dispatch('setUser', {})
          this.$store.dispatch('setIsAutnenticated', null)

          localStorage.removeItem('vuex')
          // 返回登录页面
          this.$router.push('/')
          break
      }
    },
    // 定义需要周期执行的函数
    async periodicTask() {
      // console.log('执行任务...');

      // 这里放置你的任务逻辑
      // 假设任务成功执行的条件是返回 true
      const taskSuccess = await this.doTask(); // 假设 doTask 是实际执行任务的函数
      // console.log('taskSuccess...', taskSuccess);
      // 如果任务成功执行
      if (taskSuccess && this.intervalId) {
        // 弹窗
        this.close()
        this.$nextTick(()=>{
          this.setDialogShowPaySuccessFlag(true)
          // 刷新当前页面

        })

        // 清除定时器，停止周期性执行
        clearInterval(this.intervalId);
      }
    },
    // 假设这是实际执行任务的函数，返回布尔值表示任务是否成功
    async doTask() {
      // 任务逻辑...
      // 假设任务执行成功
      // console.log('doTask user', this.user);
      if(!this.user || !this.user.id){
        return false;
      }

      // console.log("payTime: " , this.payTime);
      let res = await OrderApi.page({accountId: this.user.id, payTime: this.payTime});
      if(res.data.code == 0 && Number(res.data.data.total) > 0){
         this.energyPaySuccess = res.data.data.records[0]
         return true;
      }else{
        return false;
      }
    }
  }
}
</script>

<style scoped lang="scss">
div {
  color: #ffffff;
}
.org-logo {
  width: 2rem;
  height: auto;
}
.user-avatar {
  width: 2rem;
  height: auto;
}
img {
  /* border-radius: 50%; */
  vertical-align: middle;
}
span {
  vertical-align: middle;
  padding-left: 0.5rem;
}
.writer-cashier {
  color: #000000;
  div {
    color:  #000000;
  }
  padding: 2rem;
  &-info {
    font-weight: 400;
    font-size: 1rem;

    &-item {
      padding: .6rem 0;
      padding-left: 4.25rem;
    }
  }
}
.writer-cashier-dialog {
  /deep/ .el-dialog__header {
    padding: 0;
  }
  /deep/ .el-dialog__body {
    padding: 0 !important;
    background-image: url('../assets/pay-success-bg.png');
    background-repeat: no-repeat;
    background-position: right bottom;
  }
}
.recharge-dialog {
  /deep/ .el-dialog__header {
    padding: 0;
  }
  /deep/ .el-dialog__body {
    padding: 0 !important;
  }
}
/deep/ .el-dropdown-menu__item {
  color: #ffffff !important;
  padding: 0 10px;
  margin: 0 10px;
  line-height: 2rem;
}
/deep/ .el-dropdown-menu__item:hover {
  background-color: rgba(92, 90, 90, .4) !important;
  border-radius: 6px;
  color: #ffffff !important;
}
.custom-dropdown {
  /deep/ .popper__arrow {
    border-bottom-color: #000000 !important;
  }
  /deep/ .popper__arrow::after {
    border-bottom-color: #000000 !important;
  }
}
.newer-gift-dialog {
  /deep/ .el-dialog {
    background: transparent !important;
    border: none;
    box-shadow: 0 0 0 transparent;
  }
  /deep/ .el-dialog__headerbtn .el-dialog__close {
      color: transparent;
      background: transparent;
      border: none;
  }
}
.invite-newer-dialog {
  border: none;
  border-radius: 20px 20px 20px 20px;
  /deep/ .el-dialog__header {
    border: none;
   background: transparent;
  }
  /deep/ .el-dialog__body {
    border: none;
    background-image: url('../assets/invite-bg.png');
    background-size: auto 100%; /* 根据需要调整 */
    background-repeat: no-repeat;
    background-position: left top;
    border-radius: 20px 20px 20px 20px;
    margin: 0 .5rem;
  }
  /deep/ .el-dialog {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
  }
  /deep/ .el-dialog__headerbtn .el-dialog__close {
      color: #ffffff;
      background-color: rgba(255, 255, 255, .4);
      border: none;
      border-radius: 100%;
      padding: .2rem;
  }
}
.invite-newer-dialog-mobile {
  /deep/ .el-dialog {
    margin: 3rem 0 !important;
  }
  /deep/ .el-dialog__body {
    // background-size: auto 100% !important;
  }
}
/* 鼠标悬停时的样式 */
.invite-button:hover {
  opacity: 0.8; /* 按钮本身可以稍微透明一些，以便蒙版更明显 */
}

.invite-button::after {
  /* 蒙版层，绝对定位覆盖在按钮上 */
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: brown; /* 褐色背景 */
  opacity: 0; /* 默认透明度为0，即不显示蒙版 */
  transition: opacity 0.3s; /* 平滑过渡效果 */
}
.recharge-dialog {
  /deep/ .el-dialog__headerbtn .el-dialog__close {
      color: #FDFEFE;
      font-weight: bold;
      border: none;
  }
}
.recharge {
  span {
    padding: 0;
    vertical-align: baseline;
  }
  &-user-bg{
    font-size: 1.2rem;
    background: linear-gradient( 95deg, #075DE9 0%, #3DFFD0 100%);
    border-radius: 20px 20px 0 0;
  }
  &-user-img {
    background-image: url('../assets/recharge-bg.png');
    background-size: 60%; /* 根据需要调整 */
    background-repeat: no-repeat;
    background-position: right bottom;
    padding: 1.5rem 2rem 3.25rem 2rem;
  }
  &-user-info {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1rem;
    &-item {
      padding: .25rem 0;
    }
  }
  &-title{
    &-box {
      margin-top: -1.8rem;
      background: #ffffff;
      width: 11rem;
      padding: .4rem 0;
      border-radius: 0px 50px 0 0;
      &-text {
        color: #242424;
        font-size: 1rem;
        margin-left: .5rem;
        margin-right: 1rem;
      }
    }
  }
  &-energy {
    overflow-x: scroll;
    overflow-y: hidden;
    margin: 1rem 2rem;
    &-item {
      cursor: pointer;
      box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.11);
      border-radius: 20px;
      margin-right: 1.5rem;
      margin-bottom: .5rem;
      padding-top: .25rem;
      border: 4px solid transparent;
      &-title {
        background: linear-gradient( 289deg, #FCFDFF 0%, #F2F3FF 100%);
        border-radius: 20px;
        padding: 1rem;
      }
      &-text {
        font-weight: 600;
        font-size: 2rem;
        text-wrap: nowrap;
        white-space: nowrap;
        text-align: center;
        color: #0052D9;
      }
      &-active {
        background: #E5E7FC;
        border: 4px solid #0052D9;
      }
    }
  }
  &-pay {
    background: linear-gradient( 90deg, #F4F5FF 0%, #E5E7FC 100%);
    border-radius: 20px;
    padding: 1rem;
    margin: 0rem 2rem;
    &-left {

    }
  }
}
.text-gradient {
  /* 定义渐变的起始颜色 */
  background-image: linear-gradient(to right, #07F2C5, #0052D9);

  /* 将背景裁剪到文本区域 */
  background-clip: text;

  /* 使文本颜色透明，这样背景才会透过来 */
  color: transparent;

  /* 确保文本不会被背景覆盖 */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  /* 定义文本的字体大小 */
  // font-size: 2em;
  /* 定义文本的字体 */
  // font-family: Arial, sans-serif;
  text-wrap: nowrap !important;
}
/* 隐藏滚动条 */
.container::-webkit-scrollbar {
    width: 0; /* 垂直滚动条宽度 */
    height: 0; /* 水平滚动条高度 */
}
.writer-cashier-dialog {
  /deep/  .el-dialog {
    max-width: 500px;
  }
  /deep/ .el-dialog__headerbtn .el-dialog__close {
      color: #272636;
      font-weight: bold;
      border: none;
  }
}
.slider-arraw {
      border-radius: 50%;
      color: #ffffff;
      font-size: 1.5rem;
      cursor: pointer;
      padding: .5rem !important;
      background: rgba(0,0,0,0.2);
}
.scroll-transition {
  transition: scrollLeft 1s ease; /* 调整过渡效果和时间 */
  /* 其他样式 */
}
</style>
