<template>
    <!-- 拆书 弹窗 -->
    <el-dialog
      class="custom-dialog new-changpian-dialog"
      ref="dialog"
      :visible.sync="showBreakdownDialogFlag"
      :append-to-body="true"
      :modal-append-to-body="false"
      :close-on-press-escape="true"
      :withHeader="false"
      :destroy-on-close="true"
      :show-close="true"
      :close-on-click-modal="true"
      :wrapperClosable="true"
      :width="drawerWidth"
      :top="dialogTop"
      @close="close"
      >
      <el-form label-position="top">
        <el-row type="flex" align="middle">
          <h3 style="color: rgba(0,0,0,0.9);">{{dialogTitle}}</h3>
        </el-row>
        <div>
          <el-row type="flex" justify="space-between">
            <el-col :span="24" v-if="enums.ArticleGenTypeEnum && enums.ArticleGenTypeEnum.length>0">
              <el-form-item label="请选择拆书类型" required>
                  <el-radio-group v-model="formData.articleGenType">
                    <el-radio :disabled="item.disabled" :border="false" :label="item.name" :key="index" v-for="(item,index) in enums.ArticleGenTypeEnum.filter((item) => {
                      if(item.code == 3){
                        item.desc = '长篇（暂不支持）'
                        item.disabled = true
                      }else{
                        item.disabled = false
                      }
                      return item.code > 1
                    })">{{item.desc}}</el-radio>
                  </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item prop="url" label="上传作品内容" required>
                 <ly-pic-uploader ref="lyuploader" :url="formData.url"></ly-pic-uploader>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row class="mgt-10" type="flex" justify="space-between">
            <el-col :span="showHeader?24:24" v-if="enums.BookBreakdowTypeEnum && enums.BookBreakdowTypeEnum.length>0">
              <el-form-item label="请选择拆书输出结果（可多选）" required>
                <el-checkbox-group v-model="formData.breakdownTypes">
                  <el-checkbox-button class="custom-checkbox" :disabled="item.disabled" :border="false" :label="item.name" :key="index" v-for="(item,index) in enums.BookBreakdowTypeEnum.filter((item) => {
                    return item.code <= 2
                  })">{{item.desc}}</el-checkbox-button>
                </el-checkbox-group>
              </el-form-item>
              <div style="line-height: 1rem !important;margin-top: -.8rem;font-size: 12px;color: rgba(0, 0, 0, .6);">预估消耗能量:<span style="color: #125FDC;padding: 0 .25rem;">{{314123}}</span>(仅预估，会有误差)</div>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div type="flex" justify="center" style="display: flex;justify-content: center;">
          <el-button style="padding: 10px 55px;" type="primary" @click="newBookBreakdown">开始拆书</el-button>
        </div>
      </div>
    </el-dialog>
</template>
<script>
import { mapState, mapMutations} from 'vuex'
import * as FileApi from '@/api/file'
import LyPicUploader from '@/components/PicUploader/index_drag'
export default {
  name: 'BookBreakdown',
  props: {
    enums: {
      type: Object,
      default: {ArticleTypeEnum: []}
    },
    channel: {
      type: String,
      default: 'bookbreakdown_index'
    },
  },
  components: {
    LyPicUploader
  },
  data() {
    return {
      formData: {
        articleGenType: 'GEN_BY_ZHIHU',
        fromType: 'breakdown',
        targetType: 'BOOK_BREAKDOWN',
        breakdownTypes: [],
        url: ''
      },
      rules: {
        url: [
          { required: true, message: '请选择文件', trigger: 'blur' }
        ],
      }
    }
  },
  mounted() {
    console.log('breakdown dialog mounted');
    window.addEventListener('resize', this.forceUpdate);
  },
  computed: {
    ...mapState(['user', 'story', 'showBreakdownDialogFlag']),
    drawerWidth() {
      return window.innerWidth <= 768 ? '90%' : window.innerWidth <= 1200 ? '40%' : '40%';
    },
    dialogTop() {
      return window.innerWidth <= 768 ? "0" : '10vh';
    },
    dialogTitle () {
      return '新建拆书'
    },
    showHeader() {
      return window.innerWidth <= 768
    },
  },
  created() {
    console.log('created');
  },
  methods: {
    ...mapMutations(['setStory', 'setShowBreakdownDialogFlag']),
    close(){
      this.setShowBreakdownDialogFlag(false);
    },
    newBookBreakdown() {
      if (!this.formData.articleGenType || !this.formData.articleGenType.trim()) {
          this.$message.error('请选择故事类型');
          return false;
      }
      if (!this.formData.breakdownTypes || this.formData.breakdownTypes.length == 0) {
          this.$message.error('请选择拆书输出结果');
          return false;
      }
      const file = this.$refs.lyuploader.getFile()
      this.formData.url = file.url
      this.formData.name = file.name
      console.log('this.$refs.lyuploader', this.$refs.lyuploader.getFile());
      if (!this.formData.url || this.formData.url.length == 0) {
          this.$message.error('请上传作品');
          return false;
      }
      this.$confirm('拆书预计需要 3～10 分钟，是否确认开始？', '提示', {
        confirmButtonText: '确认开始',
        cancelButtonText: '取消',
        customClass: 'custom-message-box',
        center: false
      }).then(() => {

        FileApi.create(this.formData).then(res => {
           console.log('create file', res);
           const result = res.data.data;
           this.$parent.handlePage()
           this.close()
        });

      }).catch((e) => {console.log('error', e)});
    },
    forceUpdate() {
      this.$forceUpdate(); // 强制重新渲染，以更新计算属性
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.forceUpdate);
  },
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-form--label-top .el-form-item__label {
  padding: 0;
  font-weight: normal;
}
.custom-checkbox {
  margin-right: 1rem !important;
  margin-bottom: .5rem !important;
  border: none !important;
  ::v-deep .el-checkbox-button__inner {
    border: none !important;
    background: #F2F3FF;
    border-radius: 20px;
    padding: 6px 30px;
    color: #242424;
    font-size: 14px;
    font-weight: 400;
  }

}
::v-deep .el-checkbox-button:first-child .el-checkbox-button__inner {
  border-radius: 20px;
}

::v-deep .el-checkbox-button:last-child .el-checkbox-button__inner {
  border-radius: 20px;
}
::v-deep .el-checkbox-button.is-checked .el-checkbox-button__inner {
  background-color: #366EF4 !important;
  border-color: #366EF4 !important;
  box-shadow: none;
}
::v-deep .el-form-item__label {
  color: rgba(0, 0, 0, .8);
}
</style>
<style>
.el-message-box.custom-message-box {
  border-radius: 20px !important;
  padding: .5rem;
  padding-bottom: 1rem;
}
.el-button {
  border-radius: 6px !important;
}
</style>
